import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Interpreter } from 'xstate';
import { useService } from '@xstate/react';
import { useAmplifyAuth } from '@loggi/authentication-lib';

import PAGES from 'routes/pages';
import { STATES } from 'machines/create-account.machine';
import { FORM_ACTIONS } from 'machines/utils/form';
import CreateAccountTemplate from 'view/templates/create-account';
import LoadingTemplate from 'view/templates/loading';
import LoadingErrorTemplate from 'view/templates/loading-error';
import SignupLayoutTemplate from 'view/templates/signup-layout';
import { isEnabledSms } from 'shared/utils/driver-app';
import { useFeatureSwitch } from '@loggi/firebase-feature-switches';
import featureSwitches from 'operations/config/feature-switches';

export default function CreateAccountPage({
  isDesktop,
  history,
  context: {
    services: { CreateAccountMachine }
  },
  sendEventToAnalytics
}) {
  const [current, send] = useService(CreateAccountMachine);
  const enableSendEventCreateAccountFirstStep = useFeatureSwitch(
    featureSwitches.enableSendEventCreateAccountFirstStep
  );

  const {
    state: { authenticatedUser }
  } = useAmplifyAuth();

  const onBack = () =>
    history.push({
      pathname: PAGES.ONBOARDING.path,
      state: { enableSmsAuth: isEnabledSms() }
    });
  const retryLoadingCities = () => send('RETRY');

  useEffect(() => {
    if (current.matches(STATES.loading)) {
      send(FORM_ACTIONS.change, {
        name: 'name',
        value: authenticatedUser.name,
        touched: false
      });
      send(FORM_ACTIONS.change, {
        name: 'email',
        value: authenticatedUser.email,
        touched: false
      });
    }

    if (current.matches(STATES.finished)) {
      if (enableSendEventCreateAccountFirstStep) {
        sendEventToAnalytics('create_account_first_step', {
          email: current.context.fields.email.value,
          phone_number: current.context.fields.mobileNumber.value,
          name: current.context.fields.name.value
        });
      }
      history.push(PAGES.VERIFICATION_CODE.path, {
        from: PAGES.CREATE_ACCOUNT.path
      });
    }
  });

  const editingComponent = () => (
    <CreateAccountTemplate
      isDesktop={isDesktop}
      cities={current.context.cities}
      transportTypes={current.context.transportTypes}
      CreateAccountMachine={CreateAccountMachine}
    />
  );
  const component =
    {
      [STATES.finished]: () => <LoadingTemplate />,
      [STATES.loading]: () => <LoadingTemplate />,
      [STATES.failure]: () => (
        <LoadingErrorTemplate onRetry={retryLoadingCities} />
      )
    }[current.value.editing || current.value] || editingComponent;

  return (
    <SignupLayoutTemplate
      isDesktop={isDesktop}
      page={component()}
      onBack={onBack}
    />
  );
}

CreateAccountPage.propTypes = {
  isDesktop: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  context: PropTypes.shape({
    services: PropTypes.shape({
      CreateAccountMachine: PropTypes.instanceOf(Interpreter)
    })
  }).isRequired,
  sendEventToAnalytics: PropTypes.func
};

CreateAccountPage.defaultProps = {
  sendEventToAnalytics: () => {}
};
